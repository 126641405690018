import React from 'react'
import loadable from "@loadable/component";
import cx from "classnames";
import CategoryTitle from "../helpers/categoryTitle.amp";

const ArticlePreview = loadable(() => import("../../templates/articlePreview.amp"));

const WhatNext = (props) => {
    const className = cx(
        "my-8",
        props.className,
    );

    const articleClass = cx(
        props.articleClass || "col-span-2 lg:col-span-1"
    );

    return (
        (props.recommendedPosts || []).length > 0 && <div className={className} data-datocms-noindex>
            <CategoryTitle className={"mb-2 text-lg normal-case"} title={props.title || "What to read next..."}/>
            <div className="grid grid-cols-4 gap-6">
                {props.recommendedPosts.map((item, index) => {
                    return <ArticlePreview
                        className={`read-more ${articleClass}`}
                        headingClass={"text-lg font-arial leading-snug px-2"}
                        key={`what-next-${index}`}
                        data={item} noBody={true}
                        noFlag={true}
                        noAuthor={true}/>
                })}
            </div>
        </div>
    )
}

export default WhatNext
